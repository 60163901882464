<template>
  <div>
    <UnitList />

    <ul class="kt-menu__nav poschodoch-aside-fixed-menu">
      <!-- Units -->
      <li
        v-if="unit.count && unit.count > 1"
        aria-haspopup="true"
        data-ktmenu-submenu-toggle="hover"
        class="kt-menu__item"
      >
        <a
          href="javascript:void(0)"
          v-b-modal.modal-unit-list
          class="kt-menu__link router-link-exact-active router-link-active"
        >
          <i class="kt-menu__link-icon flaticon-interface-7"></i>
          <span class="kt-menu__link-text">
            {{ $t("MENU.UNIT_LIST") }} ({{ unit.count }})
          </span>
        </a>
      </li>

      <!-- Unit info -->
      <li
        class="p-4 text-white kt-bg-brand"
        style="white-space: pre;"
        v-if="unit.address"
      >
        <h5>{{ unit.address }}</h5>
        <div class="d-flex">
          <div class="col-6" style="padding-left: 0px !important">
            {{ unit.abstractSymbol }}
          </div>
          <div class="col-6 d-flex justify-content-end">
            <a
              href="javascript:void(0)"
              v-if="connectedAccounts > 1"
              @click="$router.push({ name: 'domus-connected-accounts' })"
              class="poschodoch-icon-link p-2 d-flex text-white"
            >
              <i class="flaticon-users poschodoch-icon-medium text-white"></i>
              {{ connectedAccounts }}
            </a>
          </div>
        </div>
      </li>
    </ul>
    <ul class="kt-menu__nav pt-0 poschodoch-aside-nav-menu">
      <template v-for="(menu, i) in menuItems">
        <KTMenuItem v-if="!menu.section" :menu="menu" :key="i"></KTMenuItem>
        <KTMenuSection
          v-if="menu.section"
          :menu="menu"
          :key="i"
        ></KTMenuSection>
      </template>
    </ul>
  </div>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import ApiService from "@/common/api.service";
import UnitList from "@/views/pages/auth/UnitList.vue";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTAsideMenu",
  data() {
    return {
      items: [],
      routeMapping: {
        account: "account",
        DocumentListObject: "documentListObject",
        DocumentListUser: "documentListUser",
        summary: "dashboard",
        consumption: "consumption",
        MonthlyConsumption: "monthlyConsumption",
        PaymentOrder: "paymentOrder",
        PaymentReminderOverview: "paymentReminderOverview",
        AnnualBilling: "annualBilling",
        DailyConsumptionCompare: "dailyConsumptionAverageObject",
        MeterReadings: "meterReadings",
        DailyConsumption: "dailyConsumption",
        AverageDayConsumption: "averageDayConsumption",
        ObjectOfficials: "objectOfficials",
        inspection: "inspection",
        BuildingElements: "buildingElements",
        Costs: "costs",
        bids: "bids",
        RepairFund: "repairFund",
        invoices: "invoices",
        ObjectAccount: "objectAccount",
        CashRegister: "cashRegister",
        servicedeskObject: "requestListHouse",
        servicedesk: "requestListUser",
        servicedeskPenta: "requestPenta",
        VotingSheet: "votingSheet",
        SignatureSheet: "signatureSheet",
        Balances: "balances",
        code449: "unpaidDebts",
        code450: "unpaidDebtsObject",
        Decisions: "decisions",
        Events: "events",
        AdminIdentity: "identityTakeover",
        AdminAccountList: "adminAccountList",
        AdminManagerList: "adminManagerList",
        RegLogList: "regLogList",
        DailyConsumptionYears: "dailyConsumptionMoreYears",
        DailyConsumptionYearsComp: "dailyConsumptionMoreYearsComp",
        MonthlyConsumptionYears: "monthlyConsumptionMoreYears",
        MonthlyConsumptionYearsDate: "consumptionForPeriod",
        AnnualCost: "annualCosts",
        code504: "annualConsumption",
        code509: "monthlyConsumptionAverageObject",
        UserInfo: "userInfo",
        CustReport: "custReport",
        DailyConsumptionObject: "dailyConsumptionObject",
        EulaPrices: "eulaPrices",
        DocumentListAdmin: "documentListAdmin",
        CustomerPages: "customerPages",
        InsurenceEvents: "insurenceEvents",
        UserListOfficials: "userListOfficials",
        UserListOfficialsDetail: "userListOfficialsDetail",
        MeterDailyReadings: "meterDailyReadings",
        MeterDailyReadingsUser: "meterDailyReadingsUser",
        MeterDailyReadingsObject: "meterDailyReadingsObject",
        AccountListManager: "accountListManager",
        AdminCustomerList: "adminCustomerList",
        AdminCheckCode: "adminCheckCode",
        Partners: "partners",
        UnpaidPrePayments: "unpaidPrePayments",
        UnpaidPrePaymentsForUser: "unpaidPrePaymentsForUser",
        MonthlyConsumptionOverview: "monthlyConsumptionOverview",
        PaymentReminderOverviewHouse: "paymentReminderOverviewHouse",
      },
      iconMapping: {
        object: "flaticon-home-2",
        flat: "poschodoch-icon-byt",
        meter_readings: "flaticon-graphic-2",
        summary: "flaticon-analytics",
        servicedesk: "flaticon-interface-3",
        servicedeskObject: "flaticon-interface-3",
        servicedeskPenta: "flaticon-interface-3",
        Events: "flaticon-calendar-3",
        DocumentListAdmin: "flaticon-file-2",
      },
      unit: {
        address: "",
        abstractSymbol: "",
        count: null,
      },
      connectedAccounts: null,
    };
  },
  components: {
    KTMenuItem,
    KTMenuSection,
    UnitList,
  },
  methods: {
    createRoute(menuCode, menuId) {
      let route = this.routeMapping[menuCode];

      if (route == undefined || route == null) {
        return "not-defined-route";
      } else {
        route += "?menuId=" + menuId;
      }
      return route;
    },
  },
  computed: {
    menuItems() {
      let menuItems = [...menuConfig.aside.items];

      for (let i = 0; i < this.items.length; i++) {
        menuItems.push(this.items[i]);
      }

      return menuItems;
    },
  },
  mounted() {
    // loading unit info from api
    ApiService.setHeader();
    ApiService.get("Dashboard/UnitInfo")
      .then(({ data }) => {
        this.unit.address = data.Text1;
        this.unit.abstractSymbol = data.Text2;
        JwtService.saveCurrency(data.Currency);
        //JwtService.saveDataTo("teraz");
      })
      .catch((error) => {
        console.log(error);
      });

    // loading unit list from api
    ApiService.setHeader();
    ApiService.get("Auth/UnitList")
      .then(({ data }) => {
        this.unit.count = data.length;
      })
      .catch((error) => {
        console.log(error);
      });

    // loading a single value from this API
    ApiService.setHeader();
    ApiService.get("Dashboard/CompanyInfo").then(({ data }) => {
      this.connectedAccounts = data.ConnectedAccounts;
    });

    // loading menu from api
    ApiService.setHeader();
    ApiService.get("Dashboard/Menu")
      .then(({ data }) => {
        this.items = [];
        let sections = [];
        let sectionsContent = [];

        for (let i = 0; i < data.length; i++) {
          let section = data[i].MenuGroupCode;
          let sectionName = data[i].MenuGroupName;
          let route = this.createRoute(data[i].MenuCode, data[i].MenuId);

          let icon = null;
          if (data[i].MenuGroupCode != null && data[i].MenuGroupCode != "") {
            icon = this.iconMapping[data[i].MenuGroupCode];
          } else if (data[i].MenuCode != null && data[i].MenuCode != "") {
            icon = this.iconMapping[data[i].MenuCode];
          }
          if (route != "not-defined-route") {
            if (!sections.includes(section) && section != null) {
              // add new section
              sections.push(section);

              // add new page with section
              sectionsContent[section] = {
                title: sectionName,
                root: true,
                icon: icon,
                bullet: "dot",
                submenu: [{ title: data[i].MenuName, page: route }],
              };
            } else if (section != null) {
              // add page to section
              sectionsContent[section].submenu.push({
                title: data[i].MenuName,
                page: route,
              });
            } else {
              // add standalone page
              this.items.push({
                title: data[i].MenuName,
                root: true,
                icon: icon,
                page: route,
                bullet: "dot",
              });
            }
          }
        }

        for (let i = 0; i < sections.length; i++) {
          this.items.push(sectionsContent[sections[i]]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
