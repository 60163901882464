<template>
  <!-- begin:: Aside -->
  <div
    class="kt-aside__brand kt-grid__item poschodoch-brand"
    id="kt_aside_brand"
  >
    <div class="kt-aside__brand-logo">
      <router-link to="/">
        <b-img fluid alt="Poschodoch logo" :src="siteLogo()"></b-img>
      </router-link>
    </div>
    <div class="kt-aside__brand-tools" v-if="allowMinimize">
      <button
        class="kt-aside__brand-aside-toggler"
        id="kt_aside_toggler"
        ref="kt_aside_toggler"
      >
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352L7.384 12.03l5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M19.707 6.707a1 1 0 10-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 101.474-1.352l-4.853-5.294 5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
        </span>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
//import KTToggle from "@/assets/js/toggle.js";
import objectPath from "object-path";

export default {
  name: "KTBrand",
  mounted() {
    // const asideToggler = new KTToggle(this.$refs["kt_aside_toggler"], {
    //   target: "body",
    //   targetState: "kt-aside--minimize",
    //   togglerState: "kt-aside__brand-aside-toggler--active"
    // });
    // if (this.allowMinimize) {
    //   asideToggler.on("toggle", () => {
    //     document.body.classList.add("kt-aside--minimizing");
    //     document.body.addEventListener("transitionend", () => {
    //       document.body.classList.remove("kt-aside--minimizing");
    //     });
    //   });
    // }
    // asideToggler.on("beforeToggle", () => {
    //   if (
    //     document.body.classList.contains("kt-aside--minimize") === false &&
    //     document.body.classList.contains("kt-aside--minimize-hover")
    //   ) {
    //     document.body.classList.remove("kt-aside--minimize-hover");
    //   }
    // });
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.skin");
      // set brand logo according to domain
      let domainString = "";
      if (window.location.host.includes("enbra")) {
        domainString = "self.logo-enbra";
      } else if (window.location.host.includes("penta")) {
        domainString = "self.logo-penta";
      } else {
        if (window.location.host.includes("poschodech")) {
          domainString = "self.logo-cz";
        } else {
          domainString = "self.logo";
        }
      }

      const logoObject = this.layoutConfig(domainString);

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig(domainString);
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
};
</script>
