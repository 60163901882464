var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-unit-list","hide-footer":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.$t("UNIT_LIST.TITLE")))]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[[(_vm.errors && _vm.errors.length)?_c('div',{staticClass:"alert fade alert-danger mt-2 mb-5",class:{ show: _vm.errors && _vm.errors.length },staticStyle:{"flex-direction":"column"},attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],_c('b-table',{staticClass:"table-white poschodoch-units-table",attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"show-empty":"","size":"md","tbody-tr-class":_vm.pointerRowClass},on:{"row-clicked":_vm.tableRowClickHandler},scopedSlots:_vm._u([{key:"cell(ConnectedAccounts)",fn:function(data){return [(data.value > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2 d-flex",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changeUnitWithTxt(
                data.item.UnitId,
                data.item.PortalId,
                data.item.UnitType
              )}}},[_c('i',{staticClass:"flaticon-users-1 poschodoch-icon-medium"}),_vm._v(" "+_vm._s(data.value)+" ")]):_vm._e()]}},{key:"cell(UnitId)",fn:function(data){return [(!data.item.Selected)?_c('b-button',{attrs:{"size":"sm","variant":"primary","id":'unit_button_' + data.value},on:{"click":function($event){return _vm.changeUnit(data.value, data.item.PortalId, data.item.UnitType)}}},[_vm._v(" "+_vm._s(_vm.$t("UNIT_LIST.CHANGE_BUTTON"))+" ")]):_vm._e()]}},{key:"cell(Icon)",fn:function(data){return [_c('span',{class:data.value})]}},{key:"cell(Mobile)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row align-items-start w-100"},[_c('div',{staticClass:"d-flex align-items-start mr-3 mt-2"},[_c('span',{class:data.item.Icon})]),_c('div',{staticClass:"d-flex flex-column align-items-start w-100"},[_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between"},[_c('div',{staticClass:"d-flex flex-row align-items-end poschodoch-mobile-right mr-1"},[_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(data.item.Type)+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.VarSymb))])]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[(!data.item.Selected)?_c('b-button',{attrs:{"size":"sm","variant":"primary","id":'unit_button_' + data.item.UnitId},on:{"click":function($event){return _vm.changeUnit(
                        data.item.UnitId,
                        data.item.PortalId,
                        data.item.UnitType
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("UNIT_LIST.SELECT_BUTTON"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between mt-3"},[_c('div',[_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"600","opacity":"0.9"}},[_vm._v(" "+_vm._s(_vm.fields[3].label)+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.Address))])]),_c('div',{staticClass:"d-flex flex-column align-items-end poschodoch-mobile-right mr-1"},[(data.item.ConnectedAccounts > 1)?_c('a',{staticClass:"poschodoch-icon-link p-2 d-flex",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.changeUnitWithTxt(
                        data.item.UnitId,
                        data.item.PortalId,
                        data.item.UnitType
                      )}}},[_c('i',{staticClass:"flaticon-users-1 poschodoch-icon-medium"}),_vm._v(" "+_vm._s(data.item.ConnectedAccounts)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex w-100 flex-row justify-content-between mt-3"},[_c('div',{staticClass:"d-flex flex-row align-items-end poschodoch-mobile-right mr-1"},[_c('span',{staticClass:"ml-2",staticStyle:{"font-weight":"600","opacity":"0.9"}},[_vm._v(" "+_vm._s(_vm.fields[4].label)+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.PropertyCompany))])])])])])]}}])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }