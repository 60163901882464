<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside v-if="asideEnabled"></KTAside>
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <b-card
          v-show="serviceMessagesVisible"
          class="text-center"
          text-variant="black"
          style="margin-top: 2rem; margin-left: 15px; margin-right: 15px; border-style: hidden; background-color: var(--company-color) !important"
        >
          <b-card-body>
            <!-- Iterate over each message in the serviceMessages array -->
            <div
              class="row justify-content-center"
              v-for="(message, index) in serviceMessages"
              :key="index"
            >
              <div class="col text-center">
                <!-- Display each message in a centered span -->
                <span class="mt-3 poschodoch-homepage-text">
                  {{ message.message }}
                </span>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <div v-if="errors && errors.length" class="mb-5">
              <div
                role="alert"
                v-bind:class="{ show: errors && errors.length }"
                class="alert fade alert-danger mt-2 mb-2"
                style="flex-direction: column"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>
            </div>
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTQuickPanel></KTQuickPanel>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/htmlclass.service";
import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTQuickPanel,
    KTScrollTop,
    Loader,
  },
  data() {
    return {
      serviceMessages: [],
      serviceMessagesVisible: false, // Array to store multiple service messages
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    this.loadServiceMessages(); // Load the service message when the component is created
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
      return;
      //this.$router.replace({ name: "login" });
    }

    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--fixed");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--enabled");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--solid");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-aside-light");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside-dark");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-brand-light");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-brand-dark");
    this.removeBackground();

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 2000);
  },
  methods: {
    async loadServiceMessages() {
      try {
        this.serviceMessages = [];
        const response = await fetch(
          "/assets/media/files/service_messages.json",
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.serviceMessages = data.serviceMessages;

        if (this.serviceMessages) {
          this.serviceMessagesVisible = true;
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation (service_message):",
          error,
        );
      }
    },
    removeBackground() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-3");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-3");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-3");
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.api.apiErrors,
    }),
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      if (window.location.host.includes("enbra")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-enbra");
      }
      if (window.location.host.includes("penta")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-penta");
      }
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
