<template>
  <div>
    <b-modal id="modal-unit-list" hide-footer size="lg">
      <template v-slot:modal-title>{{ $t("UNIT_LIST.TITLE") }}</template>
      <div class="d-block text-center">
        <template>
          <!-- errors -->
          <div
            role="alert"
            v-if="errors && errors.length"
            v-bind:class="{ show: errors && errors.length }"
            class="alert fade alert-danger mt-2 mb-5"
            style="flex-direction: column"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
          <!-- errors -->
        </template>
        <b-table
          hover
          :items="items"
          :fields="fields"
          show-empty
          size="md"
          class="table-white poschodoch-units-table"
          @row-clicked="tableRowClickHandler"
          :tbody-tr-class="pointerRowClass"
        >
          <template v-slot:cell(ConnectedAccounts)="data">
            <a
              href="javascript:void(0)"
              v-if="data.value > 1"
              v-on:click="
                changeUnitWithTxt(
                  data.item.UnitId,
                  data.item.PortalId,
                  data.item.UnitType,
                )
              "
              class="poschodoch-icon-link p-2 d-flex"
            >
              <i class="flaticon-users-1 poschodoch-icon-medium"></i>
              {{ data.value }}
            </a>
          </template>
          <template v-slot:cell(UnitId)="data">
            <b-button
              size="sm"
              variant="primary"
              :id="'unit_button_' + data.value"
              v-on:click="
                changeUnit(data.value, data.item.PortalId, data.item.UnitType)
              "
              v-if="!data.item.Selected"
            >
              {{ $t("UNIT_LIST.CHANGE_BUTTON") }}
            </b-button>
          </template>
          <template v-slot:cell(Icon)="data">
            <span :class="data.value"></span>
          </template>
          <template v-slot:cell(Mobile)="data">
            <div class="d-flex flex-row align-items-start w-100">
              <div class="d-flex align-items-start mr-3 mt-2">
                <span :class="data.item.Icon"></span>
              </div>
              <div class="d-flex flex-column align-items-start w-100">
                <div class="d-flex w-100 flex-row justify-content-between">
                  <div
                    class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                  >
                    <span class="ml-2" style="font-weight: 600">
                      {{ data.item.Type }}
                    </span>
                    <span class="ml-3">{{ data.item.VarSymb }}</span>
                  </div>
                  <div
                    class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                  >
                    <b-button
                      size="sm"
                      variant="primary"
                      :id="'unit_button_' + data.item.UnitId"
                      v-on:click="
                        changeUnit(
                          data.item.UnitId,
                          data.item.PortalId,
                          data.item.UnitType,
                        )
                      "
                      v-if="!data.item.Selected"
                    >
                      {{ $t("UNIT_LIST.SELECT_BUTTON") }}
                    </b-button>
                  </div>
                </div>
                <div class="d-flex w-100 flex-row justify-content-between mt-3">
                  <div>
                    <span class="ml-2" style="font-weight: 600; opacity: 0.9">
                      {{ fields[3].label }}
                    </span>
                    <span class="ml-3">{{ data.item.Address }}</span>
                  </div>
                  <div
                    class="d-flex flex-column align-items-end poschodoch-mobile-right mr-1"
                  >
                    <a
                      href="javascript:void(0)"
                      v-if="data.item.ConnectedAccounts > 1"
                      v-on:click="
                        changeUnitWithTxt(
                          data.item.UnitId,
                          data.item.PortalId,
                          data.item.UnitType,
                        )
                      "
                      class="poschodoch-icon-link p-2 d-flex"
                    >
                      <i class="flaticon-users-1 poschodoch-icon-medium"></i>
                      {{ data.item.ConnectedAccounts }}
                    </a>
                  </div>
                </div>
                <div class="d-flex w-100 flex-row justify-content-between mt-3">
                  <div
                    class="d-flex flex-row align-items-end poschodoch-mobile-right mr-1"
                  >
                    <span class="ml-2" style="font-weight: 600; opacity: 0.9">
                      {{ fields[4].label }}
                    </span>
                    <span class="ml-3">{{ data.item.PropertyCompany }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { CHANGE_UNIT } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";
import UIService from "@/common/ui.service";
import { mapState } from "vuex";

export default {
  name: "unit-list",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "Icon",
          label: "",
          class:
            "poschodoch-icon-medium poschodoch-icon-column table-no-mobile",
          sortable: false,
        },
        {
          key: "VarSymb",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.VAR_SYMBOL"),
          sortable: true,
        },
        {
          key: "Type",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.TYPE"),
          sortable: true,
        },
        {
          key: "Address",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.ADDRESS"),
          sortable: true,
        },
        {
          key: "PropertyCompany",
          class: "text-left table-no-mobile",
          label: this.$i18n.t("UNIT_LIST.TABLE.PROPERTY_COMPANY"),
          sortable: true,
        },
        {
          key: "UnitId",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
        {
          key: "ConnectedAccounts",
          label: "",
          sortable: false,
          class: "table-no-mobile",
          formatter: (value, key, item) => {
            if (item.Selected == true) {
              item._rowVariant = "row-active";
            }
            return value;
          },
        },
        {
          key: "Mobile",
          label: "",
          sortable: false,
          class: "text-right table-mobile",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    tableRowClickHandler(record) {
      this.changeUnit(record.UnitId, record.PortalId, record.UnitType);
    },
    pointerRowClass() {
      // use item parameter if condition is needed
      return "pointer-row";
    },
    changeUnit(unitId, portalId, unitType) {
      let obj = {
        unitID: unitId,
        portalID: portalId,
        unitType: unitType,
      };
      UIService.addButtonSpinner("unit_button_" + unitId);

      this.$store
        .dispatch(CHANGE_UNIT, obj)
        .then(() => {
          // podmienky zak
          let paramsZ = "?Type=ZakTerms";
          ApiService.setHeader();
          ApiService.get("Account", "CheckProperties" + paramsZ).then(
            ({ data }) => {
              if (data.Signed === 0) {
                this.$router.push({
                  name: "registrationAgreementNew",
                  query: { type: "Z" },
                  params: { price: data.Price },
                });
              } else {
                window.location = window.location.origin;
              }
            },
          );
        })
        .catch(() => {
          UIService.removeButtonSpinner("unit_button_" + unitId);
        });
    },
    changeUnitWithTxt(unitId, portalId, unitType) {
      let obj = {
        unitID: unitId,
        portalID: portalId,
        unitType: unitType,
      };

      if (unitId == JwtService.getUnit()) {
        this.$router.push({ name: "domus-connected-accounts" });
        this.$root.$emit("bv::hide::modal", "modal-unit-list");
      } else {
        this.$store.dispatch(CHANGE_UNIT, obj).then(() => {
          this.$router.push({ name: "domus-connected-accounts" });
          this.$root.$emit("bv::hide::modal", "modal-unit-list");
          setTimeout(function() {
            window.location.reload();
          }, 200);
        });
      }
    },
    transformData(items) {
      for (let i = 0; i < items.length; i++) {
        // set selected unit
        if (items[i].UnitId == JwtService.getUnit()) {
          items[i].Selected = true;
        } else {
          items[i].Selected = false;
        }

        //set icon
        if (items[i].UnitType == 0) {
          items[i].Icon = "poschodoch-icon-byt";
        } else {
          items[i].Icon = "flaticon-home-2";
        }

        items[i].Mobile = {
          Icon: items[i].Icon,
          VarSymb: items[i].VarSymb,
          Type: items[i].Type,
          Address: items[i].Address,
          PropertyCompany: items[i].PropertyCompany,
          UnitId: items[i].UnitId,
          ConnectedAccounts: items[i].ConnectedAccounts,
        };
      }
    },
  },
  mounted: function() {
    // loading unit list
    UIService.changeSiteColorCustomer();
    ApiService.setHeader();
    ApiService.get("Auth/UnitList").then(({ data }) => {
      this.transformData(data);
      this.items = data;
    });
  },
};
</script>
